import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Seo } from "components/contentful/seo/Seo";
import { Hero } from "components/contentful/hero/Hero";
import { MediaTextContentful } from "components/contentful/media-text/MediaText";
import { MediaTextFeatures } from "components/contentful/media-text-features/MediaTextFeatures";
import { BgGradient } from "components/bg-svgs/gradients/BgGradient";
import { WrapperBgs } from "components/container/WrapperBgs";

import { UIContext } from "context/ui";
import gsap from "gsap";
import DrawSVGPlugin from "../utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitText from "../utils/SplitText/SplitText.js";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Line1 } from "components/bg-svgs/lines/culture/line1/Line1";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSRulePlugin);

export default ({ data }: any) => {
  const { isDesktop } = useContext(UIContext);
  const { contentfulData } = data;
  const seo = contentfulData.seo;

  return (
    <>
      <BgGradient />
      {seo && <Seo seo={seo} />}
      <WrapperBgs overflowMobile={true}>
        <Hero
          clearPaddingBottom={true}
          hero={{ ...contentfulData.hero, clearPaddingBottom: true }}
        />

        <MediaTextFeatures
          mediaTextFeatures={contentfulData.body[0]}
          titleInside={true}
          allFeaturesLeft={true}
          noIcons={true}
          hasParallax={false}
          lessSpacing={true}
        />
        {isDesktop && <Line1 />}
      </WrapperBgs>

      <WrapperBgs>
        <MediaTextContentful
          layout="column"
          mediaText={contentfulData.body[1]}
          hasParallax={false}
          style={{
            marginTop: isDesktop && "100px"
          }}
        />
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
  {
    contentfulData: contentfulPage(name: { eq: "Contact" }) {
      seo {
        description
        title
        twitterImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        facebookImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        no_index
        no_follow
        name
      }
      name
      body {
        ... on ContentfulMediaText {
          id
          videoUrl
          autoplay
          showPlayButton
          sectionBackground {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            gatsbyImageData
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }

        ... on ContentfulMediaTextFeatures {
          id
          title
          alignImageLeft
          items {
            description {
              raw
            }
            icon {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
          }
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        title
        text {
          raw
        }
      }
    }
  }
`;
